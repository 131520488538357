import React from "react";
import whitelogo from "../../assets/svgs/White-Logo.svg";
import linkedinlogo from "../../assets/svgs/linkedinlogo.svg";
import youtubelogo from "../../assets/svgs/youtubelogo.svg";
import instgramlogo from "../../assets/svgs/instgramlogo.svg";
import facebooklogo from "../../assets/svgs/facebooklogo.svg";
import twitterlogo from "../../assets/svgs/twitterlogo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='tr-oak-footer Dark-M px-[30px] md:px-[80px] py-3 grid md:grid-cols-3 place-items-center items-center'>
      <Link to='/'><img src={whitelogo} alt='whitelogo' /></Link>
      <div className='flex items-center gap-6 mb-2 mt-2 md:mt-0 md:mb-0'>
        <Link to='/term-and-conditions' className='light-H body-N '>
          Terms & Conditions
        </Link>

        <Link to='/privacy-policy' className='light-H body-N '>
          Privacy Policy
        </Link>
      </div>

      <div className='text-center'>
        <div className='flex items-center gap-4 justify-center'>
          <img src={linkedinlogo} alt='linkedin' />
          <img src={youtubelogo} alt='youtubelogo' />
          <img src={instgramlogo} alt='instgramlogo' />
          <img src={facebooklogo} alt='facebooklogo' />
          <img src={twitterlogo} alt='twitterlogo' />
        </div>
        <p className='body-S light-H pt-3'>
          Copyright 2024. TRI-OAK all rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;

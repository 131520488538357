import React from 'react'
import './Homepage.css'
import check from "../assets/svgs/check.svg"
import trustdollar from "../assets/svgs/trustdollar.svg"
import trustgoogle from "../assets/svgs/trust-google.svg"
import trustprocess from "../assets/svgs/trust-process.svg"
import hand from "../assets/images/hand.png"
import herobg from "../assets/images/hero-bg.png"
import mobile from "../assets/images/mobile-image.png"
import greencloud from "../assets/images/green-cloud.png"
import dollar from "../assets/images/dollar.png"
import graph from "../assets/images/graph.png"
import chart from "../assets/images/chart.png"
import key from "../assets/images/key.png"
import leadimg from "../assets/images/leads-img.png"
import person from "../assets/images/person.png"
import ringcenter from "../assets/images/ringcenter.png"
import gamiintegration from "../assets/images/gmai-integration.png"
import dollar2 from "../assets/images/dollar2.png"
import bulb from "../assets/images/bulb.png"
import commision from "../assets/images/commision.png"
import contactdetail from "../assets/images/contactdetail.png"
import heroimg from "../assets/images/hero-img.png"
import Footer from './Components/Footer'
import logo from '../assets/images/Logo.png';
import { Link } from 'react-router-dom'


const Homepage = () => {
  

    return (
        <>
            
            {/*----------- tri-oak-header -----------*/}
            
            <div className='tri-oak-header flex justify-between px-[30px] md:px-[80px] py-3'>
                 <div>
                    <Link to='/'><img src={logo} alt='logo'/></Link>
                 </div>
                 <div  >
                    <button
                        className=' bg-[#2D5B30] body-N light-H px-[40px] py-[10px] rounded-3xl '
                        onClick={() => {
                            window.open("https://app.tri-oakcrm.com/login")
                        }}
                    >Login</button>
                </div>
            </div>

            
            {/* ---------  tri-oak-hero-section-------- */}

               <div className='tri-oak-hero text-center   px-[30px] md:px-[80px] bg-cover bg-center pt-16 md:h-[770px] 2xl:h-[960px]' style={{ backgroundImage: `url(${herobg})` }}>
                <h1 className='head-1 dark-H hero-heading'>Transform Real Estate Management <br />with Smart, Seamless Solutions</h1>
                <p className='body-N dark-M pt-3 hero-desc'>Empower your business with an all-in-one platform designed to streamline property<br/> management, enhance communication,
                    and maximize efficiency. From tenant tracking to<br /> financial reporting, we’ve got you covered.</p>
                
                <div className='relative bottom-[-50px]'>
                  <img src={heroimg} alt='hero-img' className=' w-[70%] m-[auto] ' />
                </div>
               
                

                
               </div>


            {/* ----------contact-management------------ */}

            <div className='grid grid-cols-1 md:grid-cols-2 px-[30px] md:px-[80px] md:pt-20 2xl:pt-[8rem] pt-0 mt-24 md:mt-44'>
                <div>
                <div className='border-t-2 border-[#37783A] w-[20%]'></div>
                <h1 className='head-1 dark-H '>Effortless Contact Management</h1>
                    <p className='body-N dark-M'>Keep track of all your real estate connections with our powerful
                        contact management module. Whether managing individual clients or large companies,
                        easily organize, categorize, and maintain communication to streamline your workflow
                        and ensure no opportunity is missed.</p>
                    

                 <div className='flex gap-3 pt-6'>
                        <div>
                            <img src={ dollar}  alt='dollar'/>
                            <h4 className='head-2 dark-H py-2'>Individuals</h4>
                            <p className='body-S dark-M'> Maintain personal connections with ease.
                                Organize, track, and engage with individual
                                clients to build stronger relationships and
                                drive real estate success.</p>   

                        </div> 

                        <div>
                           <img src={ dollar}  alt='dollar' />
                            <h4 className='head-2 dark-H py-2'>Companies</h4>
                            <p className='body-S dark-M'>Efficiently manage company-wide contacts.
                                Handle complex business relationships with
                                robust tools that keep every interaction
                                professional and seamless..</p>   
                        </div>
                 </div>

                </div>

               <div>
                <img src={ contactdetail} className='pt-8 md:pt-0'/>
               </div>
            </div>
           
            {/*---------- Leads-section-------------- */}

            <div className='grid grid-cols-1 md:grid-cols-2  items-center px-[30px] md:px-[40px] pt-20 pb-4'>
                <div className='order-2  md:order-1'>
                    <img src={ leadimg} />
                </div>
                
                <div className=' md:pb-20 pb-9 order-1 md:order-2'>
                    <div className=' border-t-2 border-[#37783A] w-[20%] '></div>
                       <h1 className='head-1 dark-H '>Optimize Lead Management Process</h1>
                    <p className='body-N dark-M'>Capture, nurture, and convert leads with precision.
                        Our lead management module simplifies the process, helping you stay organized
                        and responsive every step of the way. Whether you're tracking new inquiries or
                        following up on potential clients, our system provides a streamlined approach to
                        handle every lead with care. Automate lead collection, categorize based on priority,
                        and set timely reminders to ensure no opportunity slips through the cracks. With real-time
                        updates and insightful analytics, you can focus on converting leads into long-term partnerships
                        and driving business growth.</p>
                    
                    <div className='homelayout-listing pt-5 '>
                        <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check} alt='check-right' /></span>
                            <p className='dark-M'>Automated Lead Capture</p></div>
                        
                        <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check} alt='check-right'  /></span>
                            <p className='dark-M'>Lead Tracking</p></div>
                        
                            <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check} alt='check-right'  /></span>
                            <p className='dark-M'>Customizable Pipelines</p></div>
                        
                            <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check} alt='check-right'  /></span>
                            <p className='dark-M'>Effortless Communication</p></div>
                        
                        <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check}  alt='check-right' /></span>
                        <p className='dark-M'>Performance Analytics</p></div>
                    </div>

                 </div>
            </div>

            {/*----------- 3-party-integration-section -----------*/}
            
            <div className='py-20 bg-[#F4F4F4] px-[30px] md:px-[40px]'>
                <div className=' border-t-2 border-[#37783A] w-[20%] m-[auto] pb-3'></div>
                 <h2 className='head-1 dark-H  text-center'>Effortless 3rd Party Integration with Your Favorite Tools</h2>
                <p className='body-N dark-M text-center paty-desc'>Connect your real estate management system with the most trusted tools
                    you use every day. Our platform offers seamless<br/> integration with leading third-party applications,
                    enhancing your ability to streamline workflows, automate routine tasks, and<br/> synchronize important
                    data across different systems. With these integrations, you can effortlessly manage communication,<br/>
                    reporting, and operations from one centralized location, making your business more efficient and
                    responsive.</p>
                
                 <div className='md:flex justify-center gap-10 pt-12'>
                    <div className='md:w-[45%] w-[100%]'>
                    <img src={ ringcenter}  alt='ringcenter'/>
                     <h4 className='head-2 dark-H  py-2'>RingCentral Integration</h4>
                        <p className='body-S dark-M'>Handle your calling needs directly within the platform
                            using RingCentral integration. Make and receive calls with ease, track
                            conversations, and manage client interactions efficiently. Our system
                            ensures every call is logged, keeping your communication streamlined
                            and centralized for better follow-up and management.</p>   
                    </div>

                    <div className='md:w-[45%] w-[100%] pt-5 md:pt-5'>
                    <img src={ gamiintegration}  alt='gamiintegration'/>
                     <h4 className='head-2 py-2 dark-H '>Gmail Integration</h4>
                        <p className='body-S dark-M'> Keep your email communication organized and
                            efficient with our Gmail integration. Whether sending property
                            updates, scheduling meetings, or responding to inquiries, our platform
                            syncs with Gmail, ensuring all your messages are accessible in one place.
                            Stay on top of every conversation without switching between applications.</p>   
                    </div>

                 </div>
            </div>

            {/* our-process-sec */}

            <div className='px-[30px] md:px-[40px] pb-0 pt-8 md:py-12 '> 
            <div className=' border-t-2 border-[#37783A] w-[15%] m-[auto] pb-3'></div>
                 <h2 className='head-1 dark-H  text-center'>Our Process</h2>
                <p className='body-N dark-M text-center pb-8'>Streamlining Property Transactions from Start to Finish</p>
                
                <hr className='hidden md:block   hr-dot mt-14 w-[70%] m-auto 2xl:w-[62%]' />
                <div className='md:flex justify-center gap-10 pt-12 text-center relative top-[-76px] z-10 mt-8 md:mt-0'>
                    <div className=' w-full md:w-[30%] '>
                        <div className='w-full '>
                          <img src={ key}  alt='key' className='m-[auto]'/>
                        </div>
                
                     <h4 className='head-2 dark-H  py-2'>Login to the Platform</h4>
                        <p className='body-S text-center dark-M'>Begin by logging into our system for access to exclusive
                            property listings and opportunities.</p>   
                    </div>

                    <div className='w-full md:w-[30%] my-4 md:my-0'>
                        <div className='w-full '>
                          <img src={ person}  alt='person' className='m-[auto]' />
                        </div>
                    
                     <h4 className='head-2 dark-H  py-2'>Connect with People’s</h4>
                        <p className='body-S dark-M'>Reach out to brokers or buyers seamlessly through our
                            integrated communication tools for smooth negotiations and inquiries.</p>   
                    </div>

                    <div className='w-full md:w-[30%]'>
                        <div className='w-full '>
                           <img src={ dollar2}  alt='dollar2' className='m-[auto]'/>
                        </div>
                   
                     <h4 className='head-2 py-2 dark-H '>Close Contracts</h4>
                        <p className='body-S dark-M'>Once agreements are made, effortlessly move towards
                            closing the contract, ensuring every step is managed and tracked efficiently.</p>   
                    </div>

                </div>
               
            </div>

          {/*------------ realstate-integration-section-------- */}
            
            <div className=' homelayout-inner grid grid-cols-1 md:grid-cols-2 px-[30px] md:px-[80px] relative'>
                <div className='homelayout-inner-left pb-20'>
                    <div className=' border-t-2 border-[#37783A] w-[20%] '></div>
                       <h1 className='head-1 dark-H pb-2'>Real Estate Intermediary Services</h1>
                      <p className='body-N dark-M'>Tri-Oak Consulting Group specializes in providing advanced consulting services and acts
                        as a crucial intermediary in the real estate market, connecting brokers and buyers or
                        sellers who are looking to complete property transactions. By bridging the gap between
                        these parties, Tri-Oak streamlines the process, ensuring smooth, efficient transactions for
                        all involved.</p>
                    
                    <div className='homelayout-listing pt-5 '>
                        <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check} alt='check-right' /></span>
                            <p className='dark-M'>Real Estate Intermediary Services</p></div>
                        
                        <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check} alt='check-right'  /></span>
                            <p className='dark-M'>Advanced Consulting Expertise</p></div>
                        
                            <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check} alt='check-right'  /></span>
                            <p className='dark-M'>Seamless Communication Integration</p></div>
                        
                            <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check} alt='check-right'  /></span>
                            <p className='dark-M'>System Integration for Efficiency</p></div>
                        
                        <div className='head-5 flex items-center gap-2 py-1'>
                        <span><img src={check}  alt='check-right' /></span>
                        <p className='dark-M'>Optimized Real Estate Solutions</p></div>
                    </div>

                 </div>
                
                 <div
                   className="homelayout-inner-right bg-cover bg-center 2xl:h-[54vh] "
                   style={{ backgroundImage: `url(${hand})` }}
                 >
                 </div>
                <img src={greencloud} className='absolute bottom-0 right-0 w-[7rem]'  alt='greenclound' />
              
            </div>

            {/*----------- trusted-partner --------------*/}
            
             <div className=' bg-[#F4F4F4] grid grid-cols-1 md:grid-cols-2 px-[30px] md:px-[80px]  pb-0 pt-20 md:py-20'>
                <div className="homelayout-inner-right m-[auto] pb-5 md:pb-0">
                    <img className='w-[80%]' src={mobile} alt='mobile-image'/>
                </div>
                
                <div className='homelayout-inner-left pt-4 pb-20'>
                    <div className=' border-t-2 border-[#37783A] w-[20%] '></div>
                       <h1 className='head-1 dark-H pb-2'>Your Trusted Partner for Seamless Real Estate Solutions</h1>
                      <p className='body-N dark-M'>At Tri-Oak Consulting Group, we understand that property
                        transactions can be complex and overwhelming. Our mission is to simplify the
                        process by providing expert consulting services and innovative tools tailored
                        to your needs. With our deep real estate knowledge and cutting-edge technology,
                        you’ll experience a smooth, transparent, and efficient transaction every time.</p>
                    
                    <div className='homelayout-listing pt-5 '>
                        <div className='flex items-start gap-5 py-1'>
                            <div><img src={trustdollar} alt='trustdollar'  className='max-w-[56px]' /></div>
                            <div>
                            <h3 className='head-2 dark-H '>Expertise in Real Estate Transactions</h3>
                            <p className='body-N dark-M mt-2'>With years of experience in the real estate industry, we ensure that every property transaction is
                                handled with professionalism and care, minimizing risks and maximizing success.</p>
                            </div>
                         
                        </div>
                        
                        <div className=' flex items-start gap-5 py-2'>
                            <div ><img src={trustgoogle} alt='trustgoogle' className='max-w-[56px]' /></div>
                            <div>
                               <h3 className='head-2 dark-H '>Advanced Technology Integration</h3>
                              <p className='body-N dark-M mt-2'>We integrate the latest platforms, such as Gmail, RingCentral, and Mailchimp, into our system,
                                making it easier for you to communicate and manage transactions effortlessly.
                              </p>
                            </div>
                            </div>
                        
                            <div className=' flex items-start gap-5 py-1'>
                            <div ><img src={trustprocess} alt='trustprocess' className='max-w-[56px]' /></div>
                            <div>
                               <h3 className='head-2 dark-H '>Transparent and Efficient Process</h3>
                              <p className='body-N dark-M mt-2'>Our streamlined approach ensures that you stay informed at every stage of
                                the transaction, with clear communication and real-time updates.</p>
                             </div>
                          </div>
                        
                          
                    </div>

                 </div>
             </div>
            

            {/* graph-chart-section */}

              <div className='grid grid-cols-1 md:grid-cols-2 gap-7 items-center px-[30px] md:px-[80px] py-14'>
                 <div className='text-center'>
                    <img src={graph} className='m-auto'/>
                    <h4 className='head-2 dark-H '>Total Smart Plans - Manage Workflow with Ease</h4>
                    <p className='body-S dark-M'>Keep track of all your active Smart Plans that automate follow-ups,
                        communication, and task management.
                        Ensure you never miss an opportunity with detailed insights into each plan’s performance.</p>
                </div>
                
                <div className='text-center'>
                    <img src={chart} className='m-auto'/>
                    <h4 className='head-2 dark-H '>Total Contacts - All Your Connections in One Place</h4>
                    <p className='body-S dark-M '>Access and manage your entire contact list effortlessly. From leads to
                        clients, view detailed information and engagement history for every contact, helping you
                        maintain strong relationships and effective communication.</p>
                </div>
              </div>

            {/* advanced-sector */}
            
            <div className='px-[30px] md:px-[40px] md:py-12 '> 
            <div className=' border-t-2 border-[#37783A] w-[15%] m-[auto] pb-3'></div>
                 <h2 className='head-1 text-center dark-H'>Advanced Analytics & Reporting</h2>
                <p className='body-N text-center pb-16 md:pb-8 advanced-desc dark-M'>Unlock deep insights with advanced analytics and reporting. Our system provides comprehensive data visualization and <br></br>customizable reports,
                    helping you make informed decisions and track performance metrics with precision.</p>
                
                <hr className='hidden md:block 2xl:w-[62%] hr-dot mt-14 w-[68%] m-auto' />
                <div className='md:flex justify-center gap-10 pt-12 text-center relative top-[-76px] z-10'>
                    <div className='w-full md:w-[30%] '>
                        <div className='w-full '>
                          <img src={ bulb}  alt='bulb' className='m-[auto]'/>
                        </div>
                
                     <h4 className='head-2 py-2 dark-H'>Email Templates</h4>
                        <p className='body-S text-center dark-M'>Save time with customizable email templates.
                            Quickly send professional, pre-formatted messages for common client interactions,
                            ensuring consistency while maintaining a personal touch.</p>   
                    </div>

                    <div className='w-full md:w-[30%] my-4 md:my-0 '>
                        <div className='w-full '>
                          <img src={ dollar2}  alt='dollar2' className='m-[auto]' />
                        </div>
                    
                     <h4 className='head-2 py-2 dark-H'>Transaction Tracking</h4>
                        <p className='body-S dark-M'>Stay on top of every deal with robust transaction tracking.
                            Monitor each step from initial inquiry to closing, ensuring transparency and
                            seamless workflow management.</p>   
                    </div>

                    <div className='w-full md:w-[30%] '>
                        <div className='w-full '>
                           <img src={commision }  alt='commision' className='m-[auto]'/>
                        </div>
                   
                     <h4 className='head-2 py-2 dark-H'>Commission Tracking</h4>
                        <p className='body-S dark-M'>Track commissions with ease. Automatically
                            calculate and record commissions for each sale, providing clear
                            insights into earnings and performance across your team.</p>   
                    </div>

                </div>
               
            </div>

             
          <Footer/>
        </>
    )
}

export default Homepage;

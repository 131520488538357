import React from 'react';
import triokbg from '../../assets/images/trioakBg.png';
import logo from '../../assets/images/Logo.png';
import Footer from './Footer'
import { Link } from 'react-router-dom';
const Term_n_conditions = () => {
    return (

        <div>
             {/*----------- tri-oak-header -----------*/}
            
             <div className='tri-oak-header flex justify-between px-[30px] md:px-[80px] py-3'>
                 <div>
                    <Link to='/'><img src={logo} alt='logo'/></Link>
                 </div>
                 <div  >
                    <button
                        className=' bg-[#2D5B30] body-N light-H px-[40px] py-[10px] rounded-3xl '
                        onClick={() => {
                            window.open("https://app.tri-oakcrm.com/login")
                        }}
                    >Login</button>
                </div>
            </div>

             <div className="flex items-center justify-center min-h-screen bg-cover bg-center px-4" style={{ backgroundImage: `url(${triokbg})` }}>
            <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-lg max-w-4xl w-full text-left m-12">
                <div className=' flex justify-center'>
                </div>
                <h2 className="head-1 dark-H mt-6 mb-3">TERMS AND CONDITIONS</h2>
                <p className=" dark-M mb-4">
                    Tri-oak is a real estate platform designed to facilitate the settlement of ownership between property owners and buyers. By using the Platform, you agree to be bound by these Terms and Conditions of Use ("Terms").
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Definitions</h2>
                <p className=" dark-M mb-4 ">
                    In these Terms, "User" refers to any individual or entity that accesses or uses the Platform. "Property" refers to any real estate property listed on the Platform. "Owner" refers to the owner of a Property listed on the Platform. "Buyer" refers to an individual or entity that purchases a Property through the Platform.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Use of the Platform</h2>
                <p className="dark-M mb-4">
                    The User agrees to use the Platform for lawful purposes only and in accordance with these Terms. The User shall not use the Platform for any illegal or unauthorized activities, including but not limited to, fraud, theft, or unauthorized access to any information or data stored on the Platform.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Registration and Account Security</h2>
                <p className="dark-M mb-4">
                    To use the Platform, the User must register for an account by providing accurate and complete information as required by the registration process. The User is responsible for maintaining the confidentiality and security of their account login credentials and ensuring that they are not shared with any unauthorized third-party individual or entity.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Properties and Listings</h2>
                <p className="dark-M mb-4">
                    The Owner is responsible for ensuring that all information provided about their Property is accurate and complete. The Owner shall be responsible for updating their Property listing information in a timely manner to ensure that it remains accurate and up-to-date.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Purchases and Sales</h2>
                <p className="dark-M mb-4">
                    The Buyer agrees to purchase a Property in accordance with these Terms and any additional terms specified in the Property listing.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Intellectual Property</h2>
                <p className="dark-M mb-4">
                    The Platform reserves all intellectual property rights in and to its software, systems, and content.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Warranties and Representations</h2>
                <p className="dark-M mb-4">
                    The Platform makes no warranties or representations regarding the completeness or reliability of any information provided by Users or third-party sources.
                </p>
            </div>
            </div>

            <Footer/>
      </div>
       

    );
};

export default Term_n_conditions;

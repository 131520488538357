import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Homepage from "./Home/Homepage";
import PrivacyPolicy from "./Home/Components/PrivacyPolicy";
import Term_n_conditions from "./Home/Components/Term_n_conditions";


function App() {
  return (
    <>
      
      <BrowserRouter>
      <Routes>
          <Route path="/" index={true} element={<Homepage />} />
          <Route path="/privacy-policy" index={true} element={<PrivacyPolicy />} />
          <Route path="/term-and-conditions" index={true} element={<Term_n_conditions />} />
     
          </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
